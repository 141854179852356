<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="mb-3">
                        <div class="">
                            <b-row>
                                <b-col sm="12" class="pb-2">
                                    <h4>Informació de l'usuari</h4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="6">
                                    <b-form-group label="Nom" label-for="account-username">
                                        <b-form-input v-model="name" placeholder="Nom"
                                                      :class="validation.name ? 'is-invalid': ''"/>
                                        <small v-if="validation.name" class="text-danger"> {{ validation.name }}</small>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-form-group label="Email" label-for="account-email">
                                        <b-form-input v-model="email" placeholder="Email"
                                                      :class="validation.email ? 'is-invalid': ''"/>
                                        <small v-if="validation.email" class="text-danger"> {{
                                                validation.email
                                            }}</small>
                                    </b-form-group>
                                </b-col>

                            </b-row>

                            <b-row>
                                <b-col sm="6">
                                    <b-form-group label="Contrasenya" label-for="account-password">
                                        <b-form-input v-model="password" placeholder="Contrasenya" type="password"
                                                      :class="validation.password ? 'is-invalid': ''"/>
                                        <small v-if="validation.password" class="text-danger"> {{
                                                validation.password
                                            }}</small>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-form-group label="Confirmació de Contrasenya"
                                                  label-for="account-password-confirmation">
                                        <b-form-input v-model="password_confirmation" type="password"
                                                      placeholder="Confirmació de Contrasenya"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="6">
                                    <b-form-group label="Departments" label-for="account-roles">
                                        <v-select
                                            v-model="department"
                                            multiple
                                            label="name"
                                            :reduce="(option) => option.id"
                                            :options="departments"
                                        />
                                        <small v-if="validation.departments" class="text-danger">
                                            {{ validation.departments }}</small>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-form-group label="Rols" label-for="account-roles">
                                        <v-select
                                            v-model="roles"
                                            multiple
                                            label="title"
                                            :reduce="(option) => option.id"
                                            :options="options"
                                        />
                                        <small v-if="validation.roles" class="text-danger"> {{
                                                validation.roles
                                            }}</small>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>


                        <b-row align-h="start">
                            <b-col cols="5">
                                <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                    Desar Canvis
                                </b-button>
                                <b-button v-else variant="primary" class="mt-2 mr-1" @click="updateData">
                                    Actualitzar Canvis
                                </b-button>
                                <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                    Cancel·lar
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import {USER_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {departments} from "@/constants/departments"

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BCard,
        BOverlay,

        vSelect,
    },
    data() {
        return {
            name: null,
            email: null,
            password: null,
            department: [],
            edit: false,
            showLoading: false,
            password_confirmation: null,

            roles: [],
            options: [],
            departments,
        }
    },
    computed: {
        ...mapState('user', ['validation']),
    },
    watch: {
        department: function (newDepartment) {
            this.getRoles()
        }
    },
    created() {
        this.$store.commit('user/CLEAR_VALIDATION')
        this.getRoles()
        if (this.$route.params.id) {
            this.edit = true
            this.getUser()
        } else {
            this.edit = false
        }
    },
    methods: {
        getRoles() {
          this.$store.dispatch('auth/getRolesByDepartment', { department: this.department })
              .then(response => {
                this.options = response.data

                if (this.roles && this.options) {
                  this.roles = this.roles.filter(role => {
                    const foundOption = this.options.some(option => option.id === role)
                    if (foundOption) {
                      return true
                    } else {
                      return false
                    }
                  })
                }
              })
              .catch(error => {
                console.log(error)
              })



        },
        getUser() {
            this.showLoading = true

            this.$store.dispatch('user/getUser', {
                relations: ['roles', 'roles.department'],
                id: this.$route.params.id,
            })
                .then(response => {
                    this.name = response.name
                    this.email = response.email
                    this.roles = response.roles.map(function (role) {
                        return role['id']
                    })
                  this.department = response.roles
                      .map(function (department) {
                        return department.department_id
                      })
                      .filter(function (department_id) {
                        return department_id !== null && department_id !== undefined
                      })


                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        sendData() {
            this.$store.dispatch('user/createUser', {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                departments: this.department,
                roles: this.roles,
            })
                .then(response => {
                    this.$router.replace(USER_LIST)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Usuari creat correctament!`,
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                        })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        updateData() {
            this.$store.dispatch('user/updateUser', {
                id: this.$route.params.id,
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                departments: this.department,
                roles: this.roles,
            })
                .then(response => {
                    this.$router.replace(USER_LIST)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Usuari actualitzat correctament!`,
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                        })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        cancel() {
            this.$router.push(USER_LIST)
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>